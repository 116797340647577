import {Injectable, OnDestroy} from '@angular/core';
import {Resolve} from '@angular/router';
import {catchError, Observable, of, Subscription, switchMap, take} from 'rxjs';
import {Store} from '@ngrx/store';
import {Authenticated, GetLoggedInUser} from './actions/auth.actions';
import * as fromRoot from './reducers/app.reducer';
import {AuthService, UserService} from '@ee/common/services';
import {AUTHORITIES, LAST_UNAUTH_ATTEMPT, LAST_UNAUTH_TS, SUBSCRIPTION, SUBSCRIPTION_START} from '@ee/common/constants';
import {SessionStorageService} from 'ngx-webstorage';

@Injectable({providedIn: 'root'})
export class UserResolver implements Resolve<boolean>, OnDestroy {
  private subs: Subscription[] = [];

  constructor(private readonly sessionStorage: SessionStorageService,
              private readonly store: Store,
              private readonly authService: AuthService,
              private readonly userService: UserService) {
  }

  resolve(): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      switchMap(response => {
        if (response.isAuthenticated) {
          return this.store.select(fromRoot.getLoggedInUser).pipe(
            take(1),
            switchMap(user => {
              if (!user) {
                this.store.dispatch(GetLoggedInUser(true));
                return this.userService.getLoggedInUser(true).pipe(
                  switchMap((retrievedUser) => {
                    // save subscription state
                    this.sessionStorage.clear(LAST_UNAUTH_TS);
                    this.sessionStorage.clear(LAST_UNAUTH_ATTEMPT);
                    this.sessionStorage.store(AUTHORITIES, retrievedUser.authorities ?? null);
                    this.sessionStorage.store(SUBSCRIPTION,
                      retrievedUser.organization?.selected_plan && retrievedUser.organization?.subscription_active ?
                        retrievedUser.organization?.selected_plan : null);
                    this.sessionStorage.store(SUBSCRIPTION_START, retrievedUser.organization?.created_date);
                    if (retrievedUser) {
                      this.store.dispatch(Authenticated(retrievedUser));
                      return of(true);
                    } else {
                      return of(false);
                    }
                  }),
                  catchError(() => of(false))
                );
              } else {
                return of(true);
              }
            })
          );
        } else {
          return of(false);
        }
      }),
      catchError(() => of(false))
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
