import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { GenericEvents } from '@newrelic/browser-agent/features/generic_events';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { SoftNav } from '@newrelic/browser-agent/features/soft_navigations';
import { Agent } from '@newrelic/browser-agent/loaders/agent';


if (environment.production) {
  enableProdMode();
}

const options = {
  init: { distributed_tracing: { enabled: true }, privacy: { cookies_enabled: true }, ajax: { deny_list: ['bam.nr-data.net'] } },
  info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: environment.nr_license_key, applicationID: environment.nr_application_id, sa: 1 },
  loader_config: { accountID: environment.nr_account_id, trustKey: environment.nr_account_id, agentID: environment.nr_agent_id, licenseKey: environment.nr_license_key, applicationID: environment.nr_application_id },
  features: [
    Ajax,
    JSErrors,
    Metrics,
    GenericEvents,
    PageViewEvent,
    PageViewTiming,
    SessionTrace,
    SoftNav
  ]
};

const newrelic = new Agent(options);
// newrelic.setApplicationVersion(environment.version);
// newrelic.addRelease(environment.version, environment.version);
// newrelic.run();
(window as any).newrelic = newrelic;

LicenseManager.setLicenseKey(environment.agGridLicense);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    console.error(err);
  });
