import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BillingDetails, SubscriptionInvoices} from '@ee/common/models';
import {GetLoggedInUser} from '../actions/auth.actions';
import {ShowAutoClosableAlert, ShowManualClosableAlert} from '../actions/alert.actions';
import {StopSaving} from '../actions/ui.actions';
import {Router} from '@angular/router';
import {BillingService} from '@ee/common/services';
import {
  BillingDetailsLoaded, BillingDetailsUpdated,
  CancelSubscription, CancelScheduledDowngrade, ChangePlan, ChangeSeats,
  InvoicesLoaded, LoadBillingDetails, LoadInvoices,
  ReactivateSubscription, SeatsChanged,
  SubscriptionCancelledReactivated, UpdateBillingDetails,
  UpdateSubscription
} from '../actions/billing.actions';

@Injectable()
export class BillingEffects {


  loadBillingDetails: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(LoadBillingDetails),
    switchMap(() => this.service.getBillingDetails()
      .pipe(
        switchMap((result: BillingDetails) => [
          BillingDetailsLoaded(result)
        ]),
        catchError((err: HttpErrorResponse) => {
          let errorMessage = 'Error loading billing details.';
          if (err.status === 404) {
            errorMessage = 'No active subscription found.';
            this.router.navigate(['/plan']);
          }
          return [new ShowAutoClosableAlert(errorMessage)];
        })
      ))
  ));

  updateBillingDetails: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UpdateBillingDetails),
    switchMap(({payload}) =>
      this.http.put<BillingDetails>(`${environment.api_prefix}api/billing`, payload)
        .pipe(
          switchMap((result: BillingDetails) => [
            BillingDetailsUpdated(result)
          ]),
          catchError(() => {
            return [new ShowAutoClosableAlert('Error updating billing details.')];
          })
        ))
  ));


  changePlan: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ChangePlan),
    switchMap(({payload}) =>
      this.http.put<BillingDetails>(`${environment.api_prefix}api/billing/change-plan`, payload)
        .pipe(
          switchMap((result: BillingDetails) => [
            BillingDetailsLoaded(result)
          ])
        ))
  ));


  modifySeats: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ChangeSeats),
    switchMap(({changeType, seats}) =>
      this.service.modifySeats(changeType, seats)
        .pipe(
          switchMap((billingDetails) => [
            SeatsChanged(changeType, seats),
            BillingDetailsLoaded(billingDetails)
          ])
        ))
  ));


  updateSubscription: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UpdateSubscription),
    switchMap(({payload}) =>
      this.http.put<BillingDetails>(`${environment.api_prefix}api/billing/subscription`, payload)
        .pipe(
          switchMap((result: BillingDetails) => {
            this.router.navigate(['/']);
            return [
              BillingDetailsLoaded(result),
              GetLoggedInUser(true),
              new StopSaving(),
              new ShowAutoClosableAlert('Subscription updated.')
            ];
          }),
          catchError((err) => {
            let errorMessage = 'Error updating subscription.';
            if (err?.error?.message) {
              errorMessage = err?.error?.message;
            }
            console.error(errorMessage);
            return [
              new ShowManualClosableAlert(errorMessage),
              new StopSaving()
            ];
          })
        ))
  ));


  cancelSubscription: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CancelSubscription),
    switchMap(() => this.http.put<boolean>(`${environment.api_prefix}api/billing/cancel`, null)
      .pipe(
        switchMap((result: boolean) => [SubscriptionCancelledReactivated(result)]),
        catchError(() => [
          new ShowAutoClosableAlert('Error cancelling subscription.')
        ])
      ))
  ));


  cancelSubscriptionChangeSchedule: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CancelScheduledDowngrade),
    switchMap(() => this.service.cancelSubscriptionChangeScheduled()
      .pipe(
        switchMap((result: BillingDetails) => [
          BillingDetailsLoaded(result)
        ]),
        catchError(() => [
          new ShowAutoClosableAlert('Error cancelling subscription update.')
        ])
      ))
  ));


  reactivateSubscription: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ReactivateSubscription),
    switchMap(() => this.http.put<boolean>(`${environment.api_prefix}api/billing/reactivate`, null)
      .pipe(
        switchMap((result: boolean) => [SubscriptionCancelledReactivated(result)]),
        catchError(() => [
          new ShowAutoClosableAlert('Error reactivating subscription.')
        ])
      ))
  ));


  subscriptionInvoices: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(LoadInvoices),
    switchMap(({payload}) =>
      this.http.get<SubscriptionInvoices[]>(`${environment.api_prefix}api/billing/invoices?max=${payload}`)
        .pipe(
          switchMap((result: SubscriptionInvoices[]) => [InvoicesLoaded(result)]),
          catchError(() => [new ShowAutoClosableAlert('Error on fetching subscription invoices.')])
        ))
  ));

  constructor(private actions$: Actions, private http: HttpClient, private service: BillingService, private router: Router) {
  }
}
