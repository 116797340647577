import {Injectable, OnDestroy} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, Subscription, withLatestFrom} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ClientDetailsDialogComponent} from '../../contacts/client-details-dialog.component';
import {Organization, Task} from '@ee/common/models';
import {AddNewClient} from '../actions/organization.actions';
import {Store} from '@ngrx/store';
import {TaskDialogComponent} from '../../shared-components/task-dialog.component';
import {SaveTask} from '../actions/task.actions';
import {DashboardSettingsDialogComponent} from '../../shared-components/dashboard-settings-dialog.component';
import {OrganizationSelectorDialogComponent} from '../../shared-components/organization-selector-dialog.component';
import {CaseSettingsDialogComponent} from '../../shared-components/case-settings-dialog.component';
import {
  ShowAddClientDialog,
  ShowCaseSettingsDialog,
  ShowEditDashboardDialog,
  ShowInvoiceCreationDialog,
  ShowOrgSwitcherDialog, ShowReferralDialog, ShowScheduledDowngradeDialog,
  ShowTaskDialog
} from '../actions/dialog.actions';
import {ClientSelectionDialogComponent} from '@ee/common/client-selection-dialog';
import {Router} from '@angular/router';
import {ReferralDialogComponent} from '../dialog/referral-dialog.component';
import * as fromApp from '../reducers/app.reducer';
import {UndoScheduledDowngradeDialogComponent} from '../../shared-components/undo-scheduled-downgrade-dialog.component';
import {CancelScheduledDowngrade} from '../actions/billing.actions';

@Injectable()
export class DialogEffects implements OnDestroy {
  subs: Subscription[] = [];

  showAddClientDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowAddClientDialog),
        map(action => {
          const dialogRef = this.dialog.open(ClientDetailsDialogComponent, {width: '750px'});
          this.subs.push(
            dialogRef.afterClosed().subscribe((createClient: Organization) => {
              if (createClient) {
                this.store.dispatch(new AddNewClient(createClient, action.redirectOnCreate));
              }
            })
          );
        })
      ),
    {dispatch: false}
  );

  showTaskDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowTaskDialog),
        map(({task}) => {
          const dialogRef = this.dialog.open(TaskDialogComponent, {
            width: '500px',
            data: task,
            closeOnNavigation: false
          });
          this.subs.push(
            dialogRef.afterClosed().subscribe((addedTask: Task) => {
              if (addedTask) {
                this.store.dispatch(SaveTask(addedTask));
              }
            })
          );
        })
      ),
    {dispatch: false}
  );

  showEditDashboardDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowEditDashboardDialog),
        map(() => {
          this.dialog.open(DashboardSettingsDialogComponent, {width: '500px', closeOnNavigation: false});
        })
      ),
    {dispatch: false}
  );

  showReferralDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowReferralDialog),
        withLatestFrom(this.store.select(fromApp.getLoggedInUser), this.store.select(fromApp.getLoggedInOrg)),
        map(([, user, org]) => {
          this.dialog.open(ReferralDialogComponent, {width: '50%', data: {accountType: org.type, user}});
        })
      ),
    {dispatch: false}
  );

  showEditCaseSettingsDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowCaseSettingsDialog),
        map(() => {
          this.dialog.open(CaseSettingsDialogComponent, {width: '500px', closeOnNavigation: false});
        })
      ),
    {dispatch: false}
  );

  showInvoiceClientSelectionsDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowInvoiceCreationDialog),
        map(() => {
          const dialogRef = this.dialog.open(ClientSelectionDialogComponent, {
            width: '500px',
            closeOnNavigation: false,
            data: {title: 'Create Invoice'}
          });
          this.subs.push(
            dialogRef.afterClosed().subscribe((selectedCompany: Organization | undefined) => {
              if (selectedCompany?.id) {
                this.router.navigate(['/invoices/create', selectedCompany.id], {onSameUrlNavigation: 'reload'});
              }
            })
          );
        })
      ),
    {dispatch: false}
  );

  showOrganizationSwitcher$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowOrgSwitcherDialog),
        map(({currentOrgId}) => {
          this.dialog.open(OrganizationSelectorDialogComponent, {
            width: '500px',
            data: currentOrgId,
            closeOnNavigation: false
          });
        })
      ),
    {dispatch: false}
  );

  showScheduledDowngradeDialog$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowScheduledDowngradeDialog),
        map(() => {
          const dialogRef = this.dialog.open(UndoScheduledDowngradeDialogComponent, {
            width: '500px',
            closeOnNavigation: false,
            disableClose: true
          });
          this.subs.push(
            dialogRef.afterClosed().subscribe((cancelDowngrade: boolean) => {
              if (cancelDowngrade) {
                this.store.dispatch(CancelScheduledDowngrade());
              } else {
                this.router.navigate(['/admin/billing'], {onSameUrlNavigation: 'reload'});
              }
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private store: Store, private dialog: MatDialog, private router: Router) {
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
