import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideNgxWebstorage, SessionStorageService, withLocalStorage, withNgxWebstorageConfig, withSessionStorage} from 'ngx-webstorage';
import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from './auth/auth.module';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './core/effects/app.effects';
import {StoreModule, USER_PROVIDED_META_REDUCERS} from '@ngrx/store';
import {getMetaReducers, reducers} from './core/reducers/app.reducer';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {PortalModule} from './portal/portal.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AlertEffects} from './core/effects/alert.effect';
import {MessageEffects} from './core/effects/message.effect';
import {IntakeEvictionEffects} from './core/effects/intake-eviction.effect';
import {WorkflowEffects} from './core/effects/workflow.effect';
import {NoteEffects} from './core/effects/note.effect';
import {AttachmentEffects} from './core/effects/attachment.effect';
import {environment} from '../environments/environment';
import {CoreModule} from './core/core.module';
import {ErrorComponent} from './landing-pages/error/error.component';
import {AuthEffects} from './core/effects/auth.effect';
import {EvictionEffects} from './core/effects/eviction.effect';
import {OrganizationEffects} from './core/effects/organization.effect';
import {AppComponent} from './app.component';
import {UserEffects} from './core/effects/user.effect';
import {FullRouterStateSerializer, provideRouterStore} from '@ngrx/router-store';
import {InvoiceEffects} from './core/effects/invoice.effect';
import {NotificationEffects} from './core/effects/notification.effect';
import {AppSearchEffects} from './core/effects/search.effects';
import {SavingInterceptor} from './core/interceptor/saving.interceptor';
import {FormEffects} from './core/effects/form.effect';
import {CountyEffects} from './core/effects/county.effect';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {BillingEffects} from './core/effects/billing.effect';
import {GuidedTourEffects} from './core/effects/guided-tour.effect';
import {ROOT_LOCAL_STORAGE_KEY, ROOT_STORAGE_KEYS} from './core/tokens/app.tokens';
import {DialogEffects} from './core/effects/dialog.effect';
import {TaskEffects} from './core/effects/task.effect';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import {Router, Scroll} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PwaService} from './pwa.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {LuxonModule} from 'luxon-angular';
import {TagInputModule} from 'ngx-chips';
import {CaseTaskEffects} from './core/effects/case-task.effect';
import {provideHotToastConfig} from '@ngneat/hot-toast';
import {MatLuxonDateModule} from '@angular/material-luxon-adapter';
import {AppGlobalErrorhandler} from './error-handler.service';
import {AuthInterceptor, AuthModule as OIDCAuthModule, LogLevel} from 'angular-auth-oidc-client';

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LuxonModule,
    CoreModule.forRoot(),
    PortalModule,
    BrowserAnimationsModule,
    AuthModule,
    TagInputModule,
    MatLuxonDateModule,
    EffectsModule.forRoot([
      AppEffects,
      AuthEffects,
      AlertEffects,
      OrganizationEffects,
      EvictionEffects,
      AttachmentEffects,
      MessageEffects,
      NoteEffects,
      IntakeEvictionEffects,
      WorkflowEffects,
      UserEffects,
      InvoiceEffects,
      NotificationEffects,
      AppSearchEffects,
      FormEffects,
      CountyEffects,
      BillingEffects,
      GuidedTourEffects,
      DialogEffects,
      TaskEffects,
      CaseTaskEffects
    ]),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox_public_key
    }),
    MatIconModule,
    NgHcaptchaModule.forRoot({
      siteKey: environment.hcaptcha_sitekey,
      languageCode: 'en'
    }),
    MatButtonModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production || environment.staging,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    OIDCAuthModule.forRoot({
      config: {
        authority: environment.cognito_domain,
        redirectUrl: window.location.origin + '/callback',
        postLogoutRedirectUri:
          `${environment.cognito_custom_domain}/logout?client_id=${environment.cognito_clientid}&logout_uri=${encodeURIComponent(window.location.origin)}`,
        clientId: environment.cognito_clientid,
        scope: 'email openid profile phone aws.cognito.signin.user.admin',
        responseType: 'code',
        useRefreshToken: true, // Enable silent refresh if supported
        secureRoutes: [`${environment.api_prefix}api`],
        logLevel: environment.production ? LogLevel.None : LogLevel.Debug
      }
    })
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    {
      provide: ErrorHandler,
      useClass: AppGlobalErrorhandler
    },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    environment.production ? [] : provideStoreDevtools({ name: 'EasyEviction', connectInZone: true }),
    provideRouterStore({ serializer: FullRouterStateSerializer }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SavingInterceptor,
      multi: true
    },
    {
      provide: ROOT_STORAGE_KEYS,
      useValue: ['auth']
    },
    {
      provide: ROOT_LOCAL_STORAGE_KEY,
      useValue: '__app_storage__'
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, SessionStorageService],
      useFactory: getMetaReducers
    },
    provideNgxWebstorage(withNgxWebstorageConfig({ prefix: 'ee', separator: '-' }), withLocalStorage(), withSessionStorage()),
    PwaService,
    provideHotToastConfig()
  ]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, router: Router, viewportScroller: ViewportScroller) {
    // Register Font Awesome
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');

    // Register layout icon SVGs
    matIconRegistry.addSvgIcon('classic', sanitizer.bypassSecurityTrustResourceUrl('content/images/icons/classic.svg'));

    viewportScroller.setOffset([0, 50]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        /* setTimeout is the core line to solve the solution */
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        }, 10000);
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}

